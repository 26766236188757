import React from "react";
import { Link } from "react-router-dom";
import data from "../data/data.json";
class Home extends React.Component {
  render(){
    return(
      <div>
        <div className="jumbotron">
          <p className="lead">
            <span style={{display: "block", fontWeight: "bold"}}>{data.tagline}</span>
            {data.brief}
          </p>
          <p className="lead">
            I live my <a href={data.author.github} target="_blank" rel="noopener noreferrer">code</a>, <a href={data.author.twitter} target="_blank" rel="noopener noreferrer">tweet</a> and <a href={data.author.blog} target="_blank" rel="noopener noreferrer">blog</a>.
          </p>
          <p className='forte'>
            My forte includes Ruby, Rails, Python, R, Golang, JavaScript, Elixir, NodeJS, Titanium (iPhone/iPad/Android), MongoDB, Redis, CouchDB, HTML5 and <Link to="/expertise">list goes on</Link>
          </p>
          <ul id="socialize">
            <li className="twitter"><a href={data.author.twitter} title="@bagwanpankaj on Twitter"  target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li className="linkedin"><a href={data.author.linkedin} title="Linkedin Profile"  target="_blank" rel="noopener noreferrer">Linkedin</a></li>
            <li className="github"><a href={data.author.github} title="Bagwan Pankaj on Github"  target="_blank" rel="noopener noreferrer">Github</a></li>
            <li className="rubygems"><a href={data.author.gems} title="Bagwan Pankaj on RubyGems"  target="_blank" rel="noopener noreferrer">RubyGems</a></li>
            <li className="tumblr"><a href={data.author.blog} title="Bagwan Pankaj on Blog"  target="_blank" rel="noopener noreferrer">Blog</a></li>
          </ul>
        </div>
        <div style={{clear: "both"}}></div>
      </div>
    )
  }
}
export default Home;
