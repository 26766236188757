import React from 'react';

function Footer() {
  return (
    <div className="container">
      <div className="footer">
        <p className='pull-left'><span className="glyphicon glyphicon-heart"></span> from Pankaj Bagwan</p>
        <span className='pull-right'>&copy; 2009-2023</span>
      </div>
    </div>
  );
}
export default Footer;
