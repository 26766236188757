import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import Home from './component/Home.js'
import Expertise from './component/Expertise.js'
import Portfolio from './component/Portfolio.js'
import Publications from './component/Publications.js'
import Footer from './component/Footer.js'

function MenuLink({ label, to, activeOnlyWhenExact }){
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });
  return (
    <li role="presentation" className={match ? "active" : ""}><Link to={to}>{label}</Link></li>
  )
}

function App() {
  return (
    <div>
      <Router>
        <div className="container">
          <div className="header clearfix">
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <h3 className="text-muted"><a className="navbar-brand" href="/">Pankaj Baagwan</a></h3>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav navbar-right">
                    <MenuLink
                      activeOnlyWhenExact={true}
                      to="/"
                      label="About"
                    />
                    <MenuLink to="/expertise" label="Expertise" />
                    <MenuLink to="/portfolio" label="Portfolio" />
                    <MenuLink to="/writings" label="Publications" />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="container">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/expertise">
              <Expertise />
            </Route>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
            <Route path="/writings">
              <Publications />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  );
}
export default App;
