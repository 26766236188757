import React from "react";
function Publications(){
  return(
    <div>
      <div className="page-header">
        <h3>Blogs/Publications</h3>
      </div>
      <div className="jumbotron">
        <p className="lead sublead">
          <a href="http://blog.bagwanpankaj.com" target="_blank" rel="noopener noreferrer">Technology Blog [Jekyll]</a>
        </p>
        <p className="lead sublead">
          <a href="https://medium.com/ducktypd" target="_blank" rel="noopener noreferrer">Technology Blog [Medium]</a>
        </p>
        <p className="lead sublead">
          <a href="http://golang.bagwanpankaj.com" target="_blank" rel="noopener noreferrer">Golang to Go [Book]</a>
        </p>
        <p className="lead sublead">
          <a href="http://elixir.bagwanpankaj.com" target="_blank" rel="noopener noreferrer">Elixir by Example [Series]</a>
        </p>
        <p className="lead sublead">
          <a href="http://github.com/bagwanpankaj" target="_blank" rel="noopener noreferrer">Github</a>
        </p>
        <p className="lead sublead">
          <a href="https://rubygems.org/profiles/bagwanpankaj" target="_blank" rel="noopener noreferrer">RubyGems</a>
        </p>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/golang"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/golang1.png" data-holder-rendered="true" style={{"height": "100px", "width": "100%", "display": "block", margin: "45px 0 45px 0"}}/>
              <div className="caption">
                <h4>Golang Slides</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/mongodb"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/mongodb1.png" data-holder-rendered="true" style={{"height": "150px", "width": "100%", "display": "block", margin: "20px 0 20px 0"}}/>
              <div className="caption">
                <h4>MongoDB Slides</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/rails"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/rails.png" data-holder-rendered="true" style={{"height": "80px", "width": "100%", "display": "block", margin: "55px 0 55px 0"}}/>
              <div className="caption">
                <h4>Ruby Rails Slides</h4>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/nodejs"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/nodejs1.png" data-holder-rendered="true" style={{"height": "100px", "width": "100%", "display": "block", margin: "45px 0 45px 0"}}/>
              <div className="caption">
                <h4>NodeJS Slides</h4>
              </div>
            </a>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/reactjs"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/reactjs.svg" data-holder-rendered="true" style={{"height": "100px", "width": "100%", "display": "block", margin: "45px 0 45px 0"}}/>
              <div className="caption">
                <h4>React.JS Slides</h4>
              </div>
            </a>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="thumbnail">
            <a href="/decks/deno"  target="_blank" rel="noopener noreferrer">
              <img alt="100%x200" src="/assets/deno.svg" data-holder-rendered="true" style={{"height": "100px", "width": "100%", "display": "block", margin: "45px 0 45px 0"}}/>
              <div className="caption">
                <h4>Deno Slides</h4>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  )
}
export default Publications;
