import React from "react";
function Expertise(){
  return(
    <div>
      <div className="page-header">
        <h3>Expertise</h3>
      </div>
      <div className="page-header">
        <h4>Ruby</h4>
        <span className="tagline">Ruby, Rails, Sinatra, Padrino and more......</span>
      </div>
      <div>
        <ul>
          <li>10+ years of experience crafting enterpise scale applications/products</li>
          <li>Worked on mostly all ruby based tools and frameworks. Including (but not limited to) Ruby, Rails, Sinatra, Padrino, RSpec and Chef. </li>
          <li>Authored/Contributed to several open source projects (visit portfolio tab/Github account)</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Javascript</h4>
        <span className="tagline">ReactJS, Angular, NodeJS, Sencha ExtJS, ElectronJS, jQuery, PhoneGap, Titanium Appcelerator and Mustache</span>
      </div>
      <div>
        <ul>
          <li>10+ years of extensive experience crafting enterpise scale applications/products</li>
          <li>Extensive experience crafting front-end experience using JavaScript and awesome tools like AngularJS, NodeJS, ExtJS</li>
          <li>Experience in handling hybrid and native mobile applications using Titanium/PhoneGap</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>NoSql (MongoDB/CouchDB/Redis)</h4>
        <span className="tagline">NoSql Please!</span>
      </div>
      <div>
        <ul>
          <li>7+ years of experience handling enormous amount of data using MongoDB/Redis</li>
          <li>Extensive experience in designing production systems for NoSQL (sharding/replication/security)</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Python</h4>
        <span className="tagline">Python, SciPy, NumPy, IPython</span>
      </div>
      <div>
        <ul>
          <li>7+ years of experience crafting regular and data applications using Python/SciPy/NumPy/IPython</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Golang</h4>
        <span className="tagline">Golang, Gobot and Docker</span>
      </div>
      <div>
        Started using Go language, in need of concurrency, it really promised. Authored some products and open source projects
        <ul>
          <li>5+ years of experience crafting golang based products</li>
          <li>Experienced in designing microservices</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Rust</h4>
        <span className="tagline">Rust, RustUp and Cargo</span>
      </div>
      <div>
        Started using Rust initially for self use and then gradually stepped up to use it in projects to solve performance issues. Authored some products and open source projects
        <ul>
          <li>4+ years of experience crafting rust based products</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>AEM</h4>
        <span className="tagline">Adobe Experience Manager (AEM)</span>
      </div>
      <div>
        Migrated Content Management product to AEM Cloud. Authored and Managed Custom Component, Sling Models and Automation scripts
        <ul>
          <li>3+ years of experience crafting AEM Cloud based products</li>
          <li>Mentoring/Training/Transforming resources and teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Deployment Automation</h4>
        <span className="tagline">Auto Pilot mode</span>
      </div>
      <div>
        <ul>
          <li>Experience in handling deployment automation using vlad/capistrano and Chef</li>
          <li>Docker</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Version Control (Git)</h4>
        <span className="tagline">Git</span>
      </div>
      <div>
        <ul>
          <li>Rigorous experience in distributed version control using Git</li>
          <li>Workflow design for various distributed.co-located teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Speaker | Corporate Training | Mentoring</h4>
        <span className="tagline">Speaker, Mentor, etc..</span>
      </div>
      <div>
        <ul>
          <li>1000+ Hours of mentoring and training resources on multiple emerging technologies</li>
          <li>Speaking on multiple technologies and delivery process</li>
          <li>Mentored/Trained several teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Project Delivery Management</h4>
        <span className="tagline">Umm...Getting shit done</span>
      </div>
      <div>
        <ul>
          <li>Experiencd Scrum Master/Certified ScrumMaster®</li>
          <li>7+ years of experience in practicing Agile using Scrum/Kanban</li>
          <li>Sound knowledge of tools and processes</li>
          <li>Trained several teams on Scrum</li>
          <li>Handled several distributed/co-located teams</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Ethical Hacking</h4>
        <span className="tagline">Cyber Security, Web/Application Security, Penetration Testing</span>
      </div>
      <div>
        <ul>
          <li>Certified Ethical Hacker</li>
          <li>Experience in Web/Application security</li>
          <li>Experience in Firewall Implementation</li>
        </ul>
      </div>
      <div className="page-header">
        <h4>Interests...</h4>
        <span className="tagline">Elixir, Machine Learning, Pig Latin(Hadoop)..</span>
      </div>
      <p>
        Exploring <a href="http://elixir-lang.org/">Elixir</a><a href="http://elixir.bagwanpankaj.com/">(blog)</a>, Machine Learning, Pig latin and curious to get my hands on Data Science.
      </p>
      <p>If you wish to hire me, I can be reached through every means of communication, here is <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvPsBsKcaPHB19jAjLu6JukDO1K3ZMZasrIQoOhoiPdbn_pg/viewform" target="_blank"  rel="noopener noreferrer">one</a> </p>
    </div>
  )
}
export default Expertise;
