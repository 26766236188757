import React from "react";
import data from "../data/data.json";
function Portfolio(){
  const portfolioItems = data.portfolio.map((port, index) =>
    <tr key={index}>
      <th><a href={data.author.github + port.gsource} target="_blank" rel="noopener noreferrer">{port.title}</a></th>
      <td>{port.description}</td>
      <td><a href={data.author.github + port.gsource} target="_blank" rel="noopener noreferrer">Source</a></td>
    </tr>
  );
  const domainItems = data.domains.map((domain, index) =>
    <p className="lead sublead" key={index}>
      {domain}
    </p>
  );
  return(
    <div>
      <div className="page-header">
        <h3>Portfolio</h3>
        <span className="tagline">Open Source Projects</span>
      </div>
      <p>Here are the list of open source project I have been working on:</p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="20%">Project Name</th>
            <th width="60%">Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {portfolioItems}
        </tbody>
      </table>
      <div className="page-header">
        <h3>Domain</h3>
      </div>
      <div className="jumbotron">
        {domainItems}
      </div>
    </div>
  )
}
export default Portfolio;
